.sepa-mandate {
  text-align: center;

  &:last-child {
    @media (max-width: 768px) {
      padding-bottom: 0px !important;
    }
  }

  @media (min-width: 768px) {
    padding-left: 4%;
    padding-right: 4%;
  }


  &__header {
    margin-top: 10px;

    &__title {
      p {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #4d4d4d;
      }
    }

    &__description {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      width: 100%;

      p {
        width: 93%;

        @media (min-width: 768px) {
          width: 74%;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #666;
  }

  &__body {
    margin-top: 30px;
  }

  &__contact {
    width: 100%;

    input[type="text"] {
      width: 100%;
      margin-bottom: 18px;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      width: 100%;

      div {
        width: 48%;
        margin-bottom: 18px;
      }
    }
  }

  &__iban {
    width: 100%;

    input[type="text"] {
      width: 100%;
    }

    &__error {
      color: #c21300;
      font-size: smaller;
      margin: 8px 0 0 0;
    }
  }

  &__form-heading {
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  &__legal {
    margin-bottom: 18px;
    margin-top: 18px;

    input[type="checkbox"] {
      margin-right: 1rem;
    }

    &__text {
      text-align: left;
    }
  }

  #sepa-mandate-button-submit {
    white-space: nowrap;
  }
}

.sepa-mandate-form {
  padding: 0px 0px;

  @media (min-width: 768px) {
    padding: 5px @actions-side-space;
  }
}


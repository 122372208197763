.claim-items {
  margin: 10px 0;

  * {
    font-size: 0.875rem;
  }

  &__header,
  &__body,
  &__footer {
    margin: 0 8px;
  }

  &__header {
    font-weight: bold;
    overflow: hidden;

    .claim-item {
      .box-shadow(none);

      &:first-of-type {
        text-align: left;
        float: left;
      }

      &:last-of-type {
        text-align: right;
        float: right;
      }
    }
  }

  &__footer {
    .claim-item {
      &__label {
        float: left;
        width: 50%;
      }

      &__amount {
        float: right;
        width: 50%;

        &.amount-field {
          text-align: right;

          .btn-primary {
            min-height: 25px;
            height: 25px;
            width: auto;
            padding: 0 0px 0 8px;
            margin: 0 0 0px 8px;
            float: right;
            font-size: 0.875rem;
            line-height: 1.5;
          }

          input {
            display: inline-block;
            min-height: 25px;
            height: 25px;
            width: 50px;
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  .claim-item {
    padding: 8px 0;
    overflow: hidden;

    &__image {
      float: left;
      margin-right: 5px;
      width: 50px;

      img {
        max-width: 50px;
      }
    }

    &__description {
      text-align: left;
      width: auto;
      overflow: hidden;
    }

    &__label {
      float: left;
      max-width: 65%;
    }

    &__amount {
      text-align: right;
      float: right;
      max-width: 35%;

      .disabled & {
        text-decoration: line-through;
      }
    }
  }
}

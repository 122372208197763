.message {
  padding: 32px 0 0 @main-side-padding;
  font-size: 1.25em;
}

.main--failure,
.main--error {
  .message {
    padding: 32px @main-side-padding 32px @main-side-padding;
    font-size: 1.25em;
    line-height: 1.5;

    &__section {
      width: calc(95% - 16px);
    }
  }
}

.auth-form {
  padding: 16px 16px 24px 16px;

  p {
    font-size: 0.875rem;
  }

  .date-input {
    & > label {
      display: none;
    }
  }

  > * {
    margin-bottom: 14px;
  }

  input,
  .field,
  .field__input {
    width: 100%;
  }

  .form {
    > * {
      margin-bottom: 15px;
    }

    > *:last-child {
      margin-bottom: 0px;
    }
  }

  .btn-primary {
    width: 100%;
  }
}

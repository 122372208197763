#XS2A-Form {
  font-size: 14px;

  h3 {
    margin-bottom: 10px;
  }

  p,
  label,
  select,
  input,
  .xs2a-description {
    outline: none;
    font-size: 0.875rem;
    color: @font-color1;
    line-height: 1.43;
  }

  .xs2a-form-line label {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 0.875rem;
    color: @font-color1;
    line-height: 1.43;
  }

  button,
  a,
  select {
    cursor: pointer;
  }

  .xs2a-submit-line :last-child {
    margin-left: 0;

    &:first-of-type {
      margin-left: auto;
    }
  }

  button {
    min-height: 40px;
    width: 100%;

    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    outline: 0 none;

    text-align: center;
    text-decoration: none;
    vertical-align: baseline;

    &:last-child {
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &.xs2a-back {
      background: none;
      box-shadow: none;
      text-decoration: underline;
      outline: 0 none;

      &:hover {
        background: none;
      }
    }
  }

  .xs2a-note {
    text-decoration: underline;
  }

  select,
  input[type="text"],
  input[type="password"] {
    background-image: none;
    padding: 15px 8px;
    width: 100%;

    &.xs2a-invalid {
      color: @error-font-color1;
    }
  }

  .xs2a-checkbox input[type="checkbox"] {
    height: 20px;
    width: 20px;

    margin-top: 10px;
    display: inline-block;
    float: none;

    border: 0;
    background: none;
    box-shadow: none;

    &.xs2a-invalid {
      color: @error-font-color1;
    }
  }

  input[type="checkbox"] + label {
    display: inline-block;
    color: gray;
    max-width: calc(100% - 25px);
    padding: 10px 0;
    float: none;
    text-align: left;
    width: 100%;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 6px 0 0 32px;
  }

  select {
    padding: 0 0 0 4px;
  }

  .xs2a-form-line {
    margin: 5px 0 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:after {
      clear: both;
      content: " ";
      display: table;
    }
  }

  .xs2a-error {
    background-color: transparent;
    color: #f6f6f6;
    padding: 0px;
    position: relative;
    margin-bottom: 0px;

    .box-sizing(border-box);

    p:last-child {
      color: rgb(255, 112, 112);
      font-weight: 400; 
      margin-bottom: 0;
    }
  }

  .xs2a-abort-line,
  .xs2a-submit-line,
  .xs2a-dialog-toolbar {
    text-align: right;
    margin-top: 10px;
  }

  .xs2a-submit {
    font-size: 1rem;
    font-weight: 600;
  }

  .xs2a-abort-line {
    button {
      background: #666;
      color: #f6f6f6;
    }
  }

  .xs2a-completion-results {
    border: 1px solid #c2c2c2;
    background-color: white;
  }

  .xs2a-completion-result {
    padding: 10px 16px;
  }

  .xs2a-completion-result:last-child {
    margin-bottom: 0;
  }

  .xs2a-completion-result-active {
    background: #e6e6e6;
  }

  .xs2a-lightbox {
    position: fixed;
    display: none;
    z-index: 98;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    & > div > div > a:not(.xs2a-dialog-button) {
      background-color: transparent;
      position: relative;
      top: 30px;
    }
  }

  .xs2a-lightbox-body {
    background: #f0f0f0;
    border: 0;
    z-index: 99;
    border-radius: 3px 0 3px 3px;

    p {
      padding: 15px;
    }

    a {
      text-decoration: underline;
    }
  }

  .xs2a-dialog {
    margin: 15% 10%;
    border-radius: 3px;
    background: #f0f0f0;
    border: 0;
    z-index: 99;
    width: 80%;
    padding: 15px;
  }

  .xs2a-dialog-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    color: #333;
    background: #d8d8d8;
    font-size: 105%;
    font-weight: bold;
    text-decoration: none;
  }

  .xs2a-align-center {
    text-align: center;
  }
}

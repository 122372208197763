.clear {
  clear: both;
}

.clear,
.clearfix:after,
.clearfix:before {
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after,
.clearfix:before {
  content: "\0020";
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  .box-sizing(border-box);
}

body {
  width: 100%;
  height: 100%;
  min-width: @layout-min-width;
  @media (min-width: 280px) {
    min-width: revert;
  }
  overflow-wrap: break-word;
  font-size: 16px;
}

.text-align-center{
  text-align: center;
}

.claim-details {
  &__sub-title {
    color: @font-color4;
  }

  &__amount {
    color: @font-color5;
  }

  .claim-grouping-labels {
    dt,
    dd {
      color: @font-color4;
    }
  }
}

.header {
  border-bottom: 1px solid black;

  .logo {
    display: block;
    height: 24px;
    width: auto;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    font-size: 1.813rem;
    font-weight: 300;
    line-height: 20px;
    font-style: normal;
    text-align: center;
  }
}

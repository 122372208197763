.actions {
  padding: 0 @actions-side-space @actions-side-space @actions-side-space;

  .claim-details,
  .payment-selector,
  .payment-modalities,
  .sepa-ticket,
  .dispute-claim,
  .deferment-form,
  .installment-plan-form,
  .sepa-mandate {
    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }

  &__section {
    border: 1px solid black;
    &__digital-contact-points {
      margin-bottom: 20px;
    }
  }
}

.main--authentication {
  .actions {
    margin-top: 32px;
  }
}

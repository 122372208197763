.customer {
  &__section {
    border: solid 2px @border-color1;
    background-color: @panel-color3;
    border-radius: 4px;
  }

  .payment-details {
    &__separator {
      background-color: @border-color1;
    }
  }
}

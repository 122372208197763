.main > .header {
  padding: 0;

  .header__section {
    width: 70%;
    max-width: @layout-max-width;
    min-width: @layout-min-width;
    padding: 20px 0;
    margin: 0 auto;
  }
}

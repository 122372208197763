.footer {
  padding: 0;

  &__links {
    text-align: right;
    float: right;
    width: 50%;
  }

  &__items {
    text-align: right;
  }

  &__logo {
    width: 50%;

    .logo {
      background-position: left;
    }
  }
}

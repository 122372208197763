@import (inline) "react-rangeslider/lib/index.css";

.rangeslider-horizontal {
  height: 7px;

  .rangeslider__fill {
    background-color: #4a3d9e;
  }

  .rangeslider__handle {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #4a3d9e;
    border: 1px solid #fff;
    box-shadow: 0 1px 3px rgb(204 204 204 / 40%), 0 -1px 3px rgb(204 204 204 / 40%);
  }

  .rangeslider__handle:after {
    display: none;
  }
}

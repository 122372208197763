.payment-preview {
  div:not(.row) {
    background-color: #f0f0f1;
  }

  .row {
    position: relative;
    overflow: unset;
    font-size: 1em;
    line-height: 1.25;
    margin-bottom: 8px;
  }

  .row:nth-child(even) > .label-text {
    width: 35%;
  }

  .header-text {
    height: 30px;
    width: 50%;
    margin-bottom: 20px;
  }

  .field-block {
    height: 35px;
    width: 100%;
    border: 1px solid #e0e0e0;
  }

  .label-text {
    height: 15px;
    width: 30%;
    margin-bottom: 5px;
  }

  .button-block {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    float: right;
    margin: 20px 0 5px;
  }
}

.claim-details {
  padding: 5px @actions-side-space;

  &__section {
    border-bottom: 1px solid @border-color1;
  }

  &__sub-title {
    font-size: 0.875rem;
    text-align: left;
  }

  &__total {
    margin-bottom: 8px;
  }

  &__title {
    margin: 10px 0;
  }

  &__amount {
    font-weight: bold;
    margin-right: 8px;
    font-size: 1.125rem;
  }

  .arrow-button {
    font-size: 0.75rem;
    background: transparent;
    color: @font-color2;
    text-decoration: underline;
    cursor: pointer;
  }

  .claim-grouping-details {
    padding: 0.5rem 0;
    border-bottom: 0.15rem solid #ccc;
  }

  .claim-grouping-body {
    padding: 0 0 0 0.5rem;
  }

  .claim-grouping-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
  }

  .claim-grouping-labels {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    dt {
      flex-basis: 40%;
      padding-right: 5px;

      &::after {
        content: ": ";
      }
    }
    dd {
      flex-basis: 60%;
      flex-grow: 1;
    }

    &::after {
      content: "";
      clear: both;
    }
  }
}

.installment-plan-form {
  .installment-number-selector-container,
  .fine-print {
    padding: 1rem 0;
  }

  .installment-number-container,
  .installment-date-container,
  .edit-plan-container {
    padding-top: 1rem;
  }

  .installment-sepa-container {
    padding: 0rem 0.5rem 1rem 0.5rem;
    background-color: #e9e9ec;

    &__no-data {
      padding: 1rem 0.5rem 0rem 0.5rem;
    }
  }

  .installment-sepa-split-label {
    width: 100%;
    display: flex;
  }

  .installment-sepa-split-label > p {
    width: 48%;
  }

  &__info-label {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &__info-label-review {
    margin-top: 30px;
  }

  .skip-button {
    display: block;
    background-color: transparent;
    font-weight: 600;
    color: #4a3d9e;
    text-decoration: none;
    border: 2px solid #4a3d9e;
    border-radius: 4px;
  }

  .skip-button:hover {
    color: white;
  }

  .installment-plan-agreement-container {
    input[type="checkbox"] {
      margin-right: 1rem;
    }
  }

  .styled-select {
    position: relative;
    display: inline-block;
    width: 100%;

    select {
      width: 100%;
      padding: 15px 20px 15px 8px;
      background-color: #fff;
      box-shadow: none;
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    select::-ms-expand {
      display: none;
    }

    &::before {
      position: absolute;
      content: "\25be";
      top: 12px;
      right: 10px;
    }
  }

  .edit-plan-container a {
    text-decoration: underline;
  }

  .font-weight-lighter {
    font-weight: lighter;
  }

  .highlight {
    font-weight: bold;
  }
}

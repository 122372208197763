.modal {
  &__content {
    background-color: @panel-color5;
    box-shadow: 0 8px 32px 0 @shadow-color1;
  }

  &__header,
  &__footer {
    background-color: @panel-color6;
  }

  &__header {
    border-bottom: 1px solid @border-color1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 1.25rem;
    font-weight: 400;
    color: @font-color2;
  }

  &__footer {
    border-top: 1px solid @border-color1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 1rem;

    .btn-secondary {
      color: @font-color2;
    }

    .btn-secondary,
    .btn-primary {
      text-decoration: none;
      font-size: 1rem;
    }
  }
}

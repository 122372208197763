.contact-collection-page-layout {
  margin: 0 auto;

  .contact-collection-page {
    width: 767px;

    .email-collection-back-btn {
      width: 19rem;
    }
  }

  .contact-collection {
    padding: 50px 0 0;
    &__consent {
      &.field__input {
        width: 307px;
      }
    }
    &__button {
      button,
      a {
        width: 307px;
      }
    }
  }
  .contact-collection-sms-sent {
    &__token-container {
      justify-content: left;
    }
    &__buttons {
      width: 21.875rem;
    }
    &__token-input {
      width: 3rem;
      height: 3rem;
      margin: 0 0.4rem;
    }
  }
}

@import "../../variables/medium";

.main {
  & > .header {
    width: 100%;

    .header__section {
      padding: 20px @main-side-padding;
    }
  }

  & > .body {
    .column-left {
      width: calc(50% - @column-space + 6px);
      float: left;
    }

    .column-right {
      width: calc(50% - @column-space);
      float: right;
    }
  }

  & > .contact {
    width: 100%;

    .contact__section {
      padding: 20px @main-side-padding 42px @main-side-padding;
    }
  }

  & > .footer {
    width: 100%;

    .footer__section {
      padding: 24px @main-side-padding;
    }
  }
}

.main--success {
  & > .body {
    .column-left {
      width: calc(65% - @column-space);
    }

    .column-right {
      width: calc(35% - @column-space);
    }
  }
}

.main--payment.main {
  & > .body {
    .column-left {
      width: calc(40% - @column-space);
      float: left;
    }

    .column-right {
      width: calc(60% - @column-space);
      float: right;
    }
  }
}

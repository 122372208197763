.customer-feedback {
  width: 100%;
  background-color: #FFFFFF;
  padding: 2rem 0.5rem;
  border-radius: 0.1rem;
  margin: 1rem auto 0 auto;

  .net-promoter-score {
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 1rem;
      font-weight: normal;
      color: inherit;
    }

    .scale {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      flex-direction: column;

      .lower-scores,
      .upper-scores {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      .lower-scores {
        @media (min-width: 320px) {
          padding: 0;
        }
        @media (min-width: 360px) {
          padding: 0 1rem;
        }
        @media (min-width: 375px) {
          padding: 0 1rem;
        }
        @media (min-width: 411px) {
          padding: 0 2.4rem;
        }
        @media (min-width: 414px) {
          padding: 0 2.5rem;
        }
        @media (min-width: 540px) {
          padding: 0 6rem;
        }
        @media (min-width: 768px) {
          margin: 0;
          padding: 0;
        }
      }

      .upper-scores {
        @media (min-width: 280px) {
          margin: 0.5rem 0;
          padding: 0 1.35rem;
        }
        @media (min-width: 320px) {
          margin: 0.5rem 0;
          padding: 0 1.25rem;
        }
        @media (min-width: 360px) {
          margin: 0.5rem 0;
          padding: 0 2.5rem;
        }
        @media (min-width: 375px) {
          margin: 0;
          padding: 0 2.5rem;
        }
        @media (min-width: 411px) {
          margin: 0;
          padding: 0 3.9rem;
        }
        @media (min-width: 414px) {
          margin: 0;
          padding: 0 4rem;
        }
        @media (min-width: 540px) {
          margin: 0;
          padding: 0 7.5rem;
        }
        @media (min-width: 768px) {
          margin: 0;
          padding: 0;
        }
      }

      .score {
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        min-width: max-content;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 1.25rem;
        font-family: inherit;
        -webkit-tap-highlight-color: transparent;
        outline: 0;
        @media (min-width: 320px) {
          margin: 0;
        }
        @media (min-width: 360px) {
          margin: 0.1rem;
        }
        @media (min-width: 375px) {
          margin: 0.3rem;
        }
        @media (min-width: 411px) {
          margin: 0.3rem;
        }
        @media (min-width: 414px) {
          margin: 0.3rem;
        }
      }

      .detractor {
        border: 0.1rem solid #e74c3c;
        background: #e74c3c20;
        color: #e74c3c;
      }

      .passive {
        border: 0.1rem solid #f39c12;
        background: #f39c1240;
        color: #f39c12;
      }

      .promoter {
        border: 0.1rem solid #2ecc71;
        background: #2ecc7140;
        color: #2ecc71;
      }

      .not-selected {
        border: 0.1rem solid #a8a8a8;
        background: #ededed;
        color: #a8a8a8;
      }
    }

    p {
      font-size: 0.8rem;
    }
  }

  .customer-effort-score {
    .question {
      p:nth-of-type(1) {
        font-size: 0.9rem;
      }

      p:nth-of-type(2) {
        margin: 0.25rem 0 0 0;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .scale {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0 0;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 0.3rem 0;
      }

      div input[type="radio"] {
        margin-right: 8px;
        border-radius: 50%;
      }

      div label {
        font-size: 0.9rem;
      }

      div:nth-of-type(1) input[type="radio"] {
        border: 0.15rem solid #e95d4a;
      }

      div:nth-of-type(1) input[type="radio"]:checked {
        border: 0.5rem solid #e95d4a;
      }

      div:nth-of-type(2) input[type="radio"] {
        border: 0.15rem solid #e67237;
      }

      div:nth-of-type(2) input[type="radio"]:checked {
        border: 0.5rem solid #e67237;
      }

      div:nth-of-type(3) input[type="radio"] {
        border: 0.15rem solid #f29f00;
      }

      div:nth-of-type(3) input[type="radio"]:checked {
        border: 0.5rem solid #f29f00;
      }

      div:nth-of-type(4) input[type="radio"] {
        border: 0.15rem solid #f2cb00;
      }

      div:nth-of-type(4) input[type="radio"]:checked {
        border: 0.5rem solid #f2cb00;
      }

      div:nth-of-type(5) input[type="radio"] {
        border: 0.15rem solid #a7bf4f;
      }

      div:nth-of-type(5) input[type="radio"]:checked {
        border: 0.5rem solid #a7bf4f;
      }

      div:nth-of-type(6) input[type="radio"] {
        border: 0.15rem solid #6fb100;
      }

      div:nth-of-type(6) input[type="radio"]:checked {
        border: 0.5rem solid #6fb100;
      }

      div:nth-of-type(7) input[type="radio"] {
        border: 0.15rem solid #458c35;
      }

      div:nth-of-type(7) input[type="radio"]:checked {
        border: 0.5rem solid #458c35;
      }
    }
  }

  .customer-satisfaction-score {
    .question {
      font-size: 1rem;
      text-align: center;
    }

    .container {
      text-align: center;
      .scale {
        margin: 1rem 0 0 0;

        .score {
          margin: 0.3rem;
          background: transparent;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          outline: 0;

          img {
            pointer-events: none;
          }
        }
      }
    }
  }

  .send-feedback {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr auto 2fr;
    grid-gap: 0.5rem;

    p {
      grid-column-start: 1;
      grid-column-end: 13;
    }

    textarea {
      height: auto;
      border-radius: 0.2rem;
      grid-column-start: 1;
      grid-column-end: 13;
      font-family: inherit;
    }

    button {
      min-width: max-content;
      grid-column-start: 1;
      grid-column-end: 13;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#skip-feedback {
  margin: 0.5rem auto 0 auto;
  text-align: center;

  button {
    text-decoration: underline;
    font-size: 0.8rem;
    cursor: pointer;
    background: transparent;
  }
}

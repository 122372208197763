.attachments {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding: @main-side-padding;

  &__header {
    border-top: 2px solid #eee;
    font-size: 1rem;
    padding: 0.5rem 0 0 0;
  }

  p.attachments__header {
    font-size: 1rem;
  }

  .attachment {
    cursor: pointer;
    flex: 1;
    font-size: 1rem;
    background-color: transparent;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    padding: 1rem 0 1rem 2.5rem;
    text-align: start;
    text-decoration: underline;
  }

  .pdf-icon {
    background-image: url("common/pdf.svg");
    color: @font-color1;
  }

  .img-icon {
    background-image: url("common/jpg.svg");
  }

  .default-icon {
    background-image: url("common/attachment.svg");
  }
}

.title {
  padding: 16px @main-side-padding 0px @main-side-padding;

  p {
    margin: 0 0 10px 0;
  }
}

.main--success {
  .title {
    padding-top: 0;
    &__section {
      h1,
      .text-h1 {
        font-size: 1.625rem;
      }
    }
  }
}

.contact-collection-initiator {
  background-color: transparent;
  padding: 1rem 0;
  .btn-primary {
    margin: 0 auto;
    width: 16rem;
  }
}
.main--success {
  .contact-collection-initiator {
    text-align: center;
    &__header,
    &__description {
      font-size: 1.125rem;
    }
  }
}

.payment-provider {
  &__section {
    border: solid 1px @border-color1;
    background-color: @panel-color1;
    border-radius: 4px;

    .box-shadow(0 8px 50px -6px @shadow-color1);
  }

  .payment-frame {
    &__footer {
      border-top: solid 1px @border-color1;
      background-color: @panel-color4;
      color: @font-color4;
    }
  }

  @import "./paymentClient/index.less";
}

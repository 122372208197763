.main {
  & > .body {
    .column-left {
      width: calc(50% - @column-space * 2);
      float: left;
    }

    .column-right {
      width: calc(50% - @column-space);
      float: right;
    }
  }
}

.main--failure,
.main--error {
  .body {
    min-height: 250px;
  }
}

.payment-selector {
  padding: 5px @actions-side-space;

  .btn-primary {
    width: 100%;
    margin: 16px 0 10px;
  }
}

.provider-list {
  &.only-item {
    display: none;
    padding-top: 5px;
  }

  &__title {
    font-weight: bold;
    margin: 8px 0;
  }

  &__item {
    position: relative;
    display: inline-block;
    padding: 6px 0 6px 30px;
    width: 100%;
    min-height: 34px;

    .tooltip-container {
      margin-left: 5px;
    }
  }

  &__label {
    max-width: calc(100% - 40px);
    font-size: 0.875rem;
  }

  &__icon {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 45px;
    height: 30px;
    border-radius: 3px;
    .box-shadow(0 0px 2px 0 #bdbdbd);
    background-image: url(common/payment-icon-default.svg);
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__radio {
    position: absolute;
    margin: 0 0 -4px -30px;
  }

  &__description {
    display: block;
    margin-right: 60px;
  }

  .icon__direct_debit .icon {
    background-image: url(common/payment-icon-sepa.svg);
  }

  .icon__direct_transfer .icon {
    background-image: url(common/payment-icon-online-transfer.svg);
  }

  .icon__trustly .icon {
    background-image: url(common/payment-icon-trustly.svg);
  }

  .icon__paypal .icon {
    background-image: url(common/payment-icon-paypal.svg);
  }

  .icon__sofort .icon {
    background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/xx_XX/pay_now/standard/pink.svg);
    background-size: cover;

    [lang="de"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/de_de/pay_now/descriptive/pink.svg);
    }
    [lang="en"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/en_gb/pay_now/descriptive/pink.svg);
    }
    [lang="fr"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/fr_fr/pay_now/descriptive/pink.svg);
    }
    [lang="nl"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/nl_nl/pay_now/descriptive/pink.svg);
    }
    [lang="it"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/it_it/pay_now/descriptive/pink.svg);
    }
    [lang="es"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/es_es/pay_now/descriptive/pink.svg);
    }
    [lang="pl"] & {
      background-image: url(https://cdn.klarna.com/1.0/shared/image/generic/badge/pl_pl/pay_now/descriptive/pink.svg);
    }
  }

  .icon__barzahlen .icon {
    background-image: url(common/payment-icon-barzahlen-viacash.svg);
    width: 100px;
  }

  .icon__ideal .icon {
    background-image: url(common/payment-icon-ideal.png);
  }

  .icon__credit_card .icon {
    .box-shadow(none);
    right: 1px;
    background-image: url(common/thumbnails-m-creditcards.png);
    width: 100px;
  }
}

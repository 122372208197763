.tooltip-container {
  display: inline-block;
  position: relative;

  .content {
    visibility: hidden;
    opacity: 0;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    transition: opacity 0.3s;
    font-size: 12px;
    bottom: 125%;
    left: -117px;
  }

  .content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  &:hover {
    .content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.status {
  display: block;
  text-align: right;

  &__icon {
    padding: 32px 16px 0 0;

    .main--success & {
      padding: 5px 0 0;

      img {
        height: 200px;
      }
    }
  }
}

.pass-code-page {
  width: 95%;
  margin: 24px auto 0 auto;
  position: relative;
  z-index: 3;
  float: none;
  box-shadow: 0 8px 50px -6px rgba(0, 0, 0, 0.18);
  border: solid 1px #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  overflow: unset;
  padding: 15px 16px 24px 16px;

  .form {
    margin: 0;

    .title {
      font-size: 21px;
      font-weight: 300;
      line-height: 1.5;
      color: #4c4c4c;
      margin: 0 0 16px 0;
    }

    .description {
      font-size: 14px;
      line-height: 1.43;
      color: #333333;
      margin: 0 0 15px 0;
      display: inline-block;
    }

    .fields {
      width: 100%;
      position: relative;
      margin: 0;
      display: inline-block;

      .field {
        text-align: center;

        &__label {
          display: block;

          text-align: left;
          font-weight: bold;
          font-size: 0.8125rem;
          color: @pass-code-secondary-color;

          padding: 0;
          margin-bottom: 7px;
        }

        &__input {
          input {
            display: block;
            border: none;
            text-align: center;

            border-radius: 0;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            height: 38px;
            padding: 8px;
            font-size: 16px;

            float: left;
            margin-right: 16px;
            margin-bottom: 8px;
            width: calc(~"(100% / 2) - 8px");

            color: @pass-code-secondary-color;
            background-color: @pass-code-bg-input-color;

            -webkit-box-shadow: 0 1px 0 0 @pass-code-tertiary-color, inset 0 2px 8px 0 @pass-code-bg-input-shadow-color;
            box-shadow: 0 1px 0 0 @pass-code-tertiary-color, inset 0 2px 8px 0 @pass-code-bg-input-shadow-color;
          }

          input:focus {
            outline: 0 !important;
            -webkit-box-shadow: inset 0 -2px 0 @pass-code-line-secondary-color,
              inset 0 2px 8px 0 @pass-code-bg-input-shadow-color;
            box-shadow: inset 0 -2px 0 @pass-code-line-secondary-color,
              inset 0 2px 8px 0 @pass-code-bg-input-shadow-color;
          }

          input:last-child {
            margin-right: 0;
          }

          .spacer {
            width: 16px;
          }
        }
      }
    }

    button {
      margin: 20px 0 0 0;
      width: 100%;
      height: 46px;
      border: none transparent;
      border-radius: 2px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      background-color: @pass-code-btn-primary-color;
    }

    button:disabled {
      opacity: 0.4;
    }
  }
}

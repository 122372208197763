.actions {
  padding: 0 @main-side-padding 0 0;
  margin: 24px 0 0 0;

  .claim-details,
  .payment-selector,
  .payment-modalities,
  .sepa-ticket,
  .dispute-claim,
  .deferment-form,
  .installment-plan-form {
    padding: 5px @actions-side-space;
  }
}

.main--authentication .actions {
  margin: 32px auto 0 auto;
  position: relative;
  width: 621px;
}

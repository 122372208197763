@import (inline) "react-datepicker/dist/react-datepicker.css";

.react-datepicker {
  font-size: 0.9rem;
  border-radius: 0;
  width: 100%;
  min-width: 17.5rem;
  max-width: 28rem;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__month-container {
  margin: 0 auto;
  float: none;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  margin: 0.4rem;
}

.react-datepicker__day {
  margin: 2px 5px;
  line-height: 1.9rem;
  width: 1.9rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  color: #000;
  font-weight: normal;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #4a3d9e;
  background: rgba(74, 61, 158, 0.5);
}

.react-datepicker__day--selected:hover {
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #4a3d9e;
  background: rgba(74, 61, 158, 1);
}
.react-datepicker__day--disabled {
  position: relative;
}

.react-datepicker__day--disabled::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 50%;
  height: 50%;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
}

.deferment-form,
.installment-plan-form,
.sepa-mandate-form,
.contact-collection-page {
  padding: 5px @actions-side-space;

  .form-item-inline {
    align-items: center;
    display: flex;
    margin: 1rem 0;

    &.align-top {
      align-items: flex-start;
    }

    > input {
      margin-top: 0.1rem;
      margin-right: 0.5rem;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"] {
    padding: 15px 12px;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  input[type="text"].number-input {
    width: 100px;
  }

  input[type="checkbox"] {
    position: relative;
  }

  input[type="text"].active,
  input[type="email"].active,
  input[type="text"]:focus,
  input[type="email"]:focus {
    border: 1px solid @contact-purple-color;
    .box-shadow(0px 0px 5px 0px @input-focus-color);
  }

  .heading {
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .sub-heading {
    line-height: 1.3334;
    font-weight: 300;
    font-size: 14px;
  }

  .hidden {
    height: 0;
    font-size: 0;
  }

  .font-size-smaller {
    font-size: smaller;
  }

  .full-width {
    width: 100%;
  }

  .highlight {
    color: #4a3d9e;
  }

  .date-picker-container {
    padding: 1rem 0;

    .react-datepicker__input-container {
      position: relative;
      display: inline-block;
    }

    .react-datepicker__input-container::before {
      position: absolute;
      content: "\25be";
      top: 12px;
      right: 10px;
    }
  }

  .slider-container {
    width: 100%;
    padding-bottom: 1rem;

    .slider-labels {
      align-items: center;
      display: flex;
      justify-content: space-between;

      > span {
        font-size: 14px;
        color: #999999;
      }
    }
  }
  .btn-primary {
    margin-bottom: 1rem;
  }
}

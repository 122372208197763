.general-feedback {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  position: fixed;
  right: 1rem;
  bottom: 1rem;

  .btn-start-feedback {
    background: #493d9d;
    color: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 0 0.5rem 0.1rem #bbb;
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
    font-size: 0.75rem;
  }

  .modal {
    min-width: revert;

    .modal__center {
      margin: 0;
    }

    #modalOverlay {
      display: flex;
      justify-content: center;
      padding: 0;

      .modal__content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        padding: 0 1rem;
        border-radius: 0;

        @media (min-width: 768px) {
          max-width: 349px;
          width: 349px;
          height: revert;
          border-radius: 4px;
        }

        .btn-close-feedback-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .btn-close-feedback {
            background: transparent;
            font-family: inherit;
            font-size: 1.5rem;
            padding-right: 19px;
            padding-top: 16px;
            @media (min-width: 280px) {
              padding-right: 0;
            }
            cursor: pointer;
          }
        }

        .question {
          text-align: center;
          font-weight: bold;
          height: 30px;
          color: #39393a;
          font-family: inherit;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 30px;
        }

        .scale-container {
          display: flex;
          justify-content: center;

          .scale {
            display: flex;
            justify-content: space-between;

            .score {
              margin: 0.3rem;
              background: transparent;
              cursor: pointer;
              -webkit-tap-highlight-color: transparent;
              outline: 0;

              img {
                pointer-events: none;
              }
            }
          }
        }

        .optional-feedback {
          display: flex;
          flex-direction: column;
          margin: 32px 0 0 0;

          div {
            display: flex;
            justify-content: center;

            p {
              width: 268px;
              font-size: 14px;
              text-align: start;
              font-weight: normal;
              color: #39393a;
              font-family: inherit;
              letter-spacing: 0;
            }
          }

          textarea {
            box-sizing: border-box;
            height: 95px;
            max-width: 268px;
            border: 1px solid #a3a3a3;
            border-radius: 3px;
            background-color: #ffffff;
            box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.15);
          }
        }

        .btn-submit-feedback-container {
          display: flex;
          flex-direction: row;
          margin: 0.5rem 0 1rem;
          justify-content: center;

          button {
            height: 48px;
            width: 268px;
          }
        }

        .thank-you-for-your-feedback {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-bottom: 1rem;

          img {
            height: 14px;
            width: 18px;
            margin: 0 0.5rem;
          }

          p {
            height: 14px;
            width: 155px;
            color: #2cad20;
            font-family: inherit;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
          }
        }
      }
    }
  }
}

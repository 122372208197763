a {
  color: @font-color2;
}
.xs2a-submit,
.btn-primary {
  background-color: @color1;
  color: @font-color3;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 20px;
  height: 48px;

  &:hover {
    background-color: @color2;
  }

  &:disabled,
  &:hover:disabled {
    background-color: @color1;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &__inversed {
    display: block;
    background-color: transparent;
    font-weight: 600;
    color: @color1;
    text-decoration: none;
    border: 2px solid @color1;
    border-radius: 0.5rem;
  }

  &__inversed:hover {
    background-color: @color1;
    color: white;
  }
}

.btn-secondary {
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  outline: none;
  height: auto;
  padding: 10px;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: @font-color5;
  line-height: 1.3334;
  font-weight: 300;
}

@h3: {
  font-size: 1.25rem;
  font-weight: 300;
};

@input-radio: {
  cursor: pointer;
  width: 22px;
  height: 22px;

  border: 2px solid @color4;
  border-radius: 10px;
  margin-right: 8px;

  vertical-align: middle;
  outline: none;
  background-color: #fff;

  .appearance(none);

  &:checked {
    border: 7px solid @color1;
  }
};

@input-text: {
  background-color: white;
  color: @font-color2;
  font-size: 1rem;
  padding: 15px 12px;
  border-radius: 8px;
  border: 1px solid @input-border-color;
  display: block;

  .appearance(none);

  &:focus,
  &:active {
    outline: 0 !important;
    border-color: @contact-purple-color;
    .box-shadow(0px 0px 5px 0px @input-focus-color);
  }
};

@input-checkbox: {
  cursor: pointer;
  line-height: normal;
  color: @font-color2;
  border: none;
  padding: 0;
  outline: 0 !important;
  vertical-align: middle;

  .appearance(none);
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid @pass-code-line-primary-color;
    border-radius: 6px;

    .transition(240ms);
  }

  &:focus::after,
  &:checked::after {
    border-color: @contact-purple-color;
  }

  &:checked::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 8px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    z-index: 1;

    .transform(rotate(45deg));
  }

  &:checked::after {
    background-color: @color1;
    border-color: @color1;
  }
};

@select: {
  outline: 0;
  border: 1px solid @input-border-color;
  &:focus,
  &:active {
    outline: 0 !important;
    border-color: @contact-purple-color;
  }
};

@option: {
  background-color: @color5;
  color: @font-color2;
  border: 0;
  outline: 0;
};

h3 {
  @h3();
}

select,
input[type="text"],
input[type="password"],
input[type="number"] {
  @input-text();
}

select {
  @select();
}

option {
  @option();
}

input[type="radio"] {
  @input-radio();
}

input[type="checkbox"] {
  @input-checkbox();
}

textarea {
  width: 100%;
  height: 148px;
  padding: 10px;
  margin: 8px auto 0 auto;
  overflow: auto;

  font-size: 1em;
  resize: none;
  color: @font-color2;
  border: solid 1px @shadow-color1;

  .box-sizing(border-box);
}

#XS2A-Form {
  select,
  input[type="text"],
  input[type="password"] {
    @input-text();
  }

  input[type="radio"] {
    @input-radio();
  }

  input[type="checkbox"] {
    @input-checkbox();
  }

  h3 {
    @h3();
  }

  option {
    @option();
  }

  select {
    @select();
  }
}

.form__error,
.field__error {
  position: relative;
  color: @error-font-color1;

  font-size: 1rem;
  padding: 14px 0 0 0;

  .box-sizing(border-box);

  p:last-child {
    margin-bottom: 0;
  }
}

.field--select {
  input {
    background-color: unset;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  .auth-select {
    &__control {
      @select();
      padding: 5px;
      border-radius: 8px;
    }

    &__input,
    &__single-value {
      color: @font-color2;
      font-size: 1rem;
    }

    &__menu {
      border-radius: 0;
      margin-top: 0;
    }

    &__menu-list {
      background-color: @color5;
      padding: 0;
    }

    &__option {
      background-color: @color5;
      color: @font-color2;
      font-size: 1rem;

      &--is-focused,
      &:active {
        background-color: @color6;
      }
    }
  }
}

.btn-primary-light {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #fff;
  color: #666;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  height: 48px;
  padding: 0 1rem;
}

.btn-primary-light:hover {
  background: #666;
  color: #fff;
}

.description {
  padding: 0;

  p,
  .text,
  .text-emphasized {
    font-size: 1.25rem;
  }

  @import "./message.less";
  @import "./title.less";
}

.box-shadow (@shadow1) {
  -webkit-box-shadow: @shadow1;
  -moz-box-shadow: @shadow1;
  box-shadow: @shadow1;
}

.box-shadow (@shadow1, @shadow2) {
  -webkit-box-shadow: @shadow1, @shadow2;
  -moz-box-shadow: @shadow1, @shadow2;
  box-shadow: @shadow1, @shadow2;
}

.box-shadow (@shadow1, @shadow2, @shadow3, @shadow4) {
  -webkit-box-shadow: @shadow1, @shadow2, @shadow3, @shadow4;
  -moz-box-shadow: @shadow1, @shadow2, @shadow3, @shadow4;
  box-shadow: @shadow1, @shadow2, @shadow3, @shadow4;
}

.box-sizing (@box-sizing) {
  -webkit-box-sizing: @box-sizing;
  -moz-box-sizing: @box-sizing;
  box-sizing: @box-sizing;
}

.appearance (@appearance) {
  appearance: @appearance;
  -moz-appearance: @appearance;
  -webkit-appearance: @appearance;
}

.transform(@transform) {
  -webkit-transform: @transform;
  -ms-transform: @transform;
  -o-transform: @transform;
  transform: @transform;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.word-wrap(@wordwrap) {
  word-wrap: @wordwrap;
  -ms-word-wrap: @wordwrap;
}

.font-face-roboto() {
  @fontpath: "common/fonts/roboto-v30";
  @filenamePrefix: "roboto-v30-latin-ext_latin";

  /* roboto-300italic - latin-ext_latin */
  @font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    src: url("@{fontpath}/@{filenamePrefix}-300italic.eot");
    src: local(""), url("@{fontpath}/@{filenamePrefix}-300italic.eot?#iefix") format("embedded-opentype"),
      url("@{fontpath}/@{filenamePrefix}-300italic.woff2") format("woff2"),
      url("@{fontpath}/@{filenamePrefix}-300italic.woff") format("woff"),
      url("@{fontpath}/@{filenamePrefix}-300italic.ttf") format("truetype"),
      url("@{fontpath}/@{filenamePrefix}-300italic.svg#Roboto") format("svg");
  }
  /* roboto-italic - latin-ext_latin */
  @font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: url("@{fontpath}/@{filenamePrefix}-italic.eot");
    src: local(""), url("@{fontpath}/@{filenamePrefix}-italic.eot?#iefix") format("embedded-opentype"),
      url("@{fontpath}/@{filenamePrefix}-italic.woff2") format("woff2"),
      url("@{fontpath}/@{filenamePrefix}-italic.woff") format("woff"),
      url("@{fontpath}/@{filenamePrefix}-italic.ttf") format("truetype"),
      url("@{fontpath}/@{filenamePrefix}-italic.svg#Roboto") format("svg");
  }
  /* roboto-300 - latin-ext_latin */
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("@{fontpath}/@{filenamePrefix}-300.eot");
    src: local(""), url("@{fontpath}/@{filenamePrefix}-300.eot?#iefix") format("embedded-opentype"),
      url("@{fontpath}/@{filenamePrefix}-300.woff2") format("woff2"),
      url("@{fontpath}/@{filenamePrefix}-300.woff") format("woff"),
      url("@{fontpath}/@{filenamePrefix}-300.ttf") format("truetype"),
      url("@{fontpath}/@{filenamePrefix}-300.svg#Roboto") format("svg");
  }
  /* roboto-regular - latin-ext_latin */
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("@{fontpath}/@{filenamePrefix}-regular.eot");
    src: local(""), url("@{fontpath}/@{filenamePrefix}-regular.eot?#iefix") format("embedded-opentype"),
      url("@{fontpath}/@{filenamePrefix}-regular.woff2") format("woff2"),
      url("@{fontpath}/@{filenamePrefix}-regular.woff") format("woff"),
      url("@{fontpath}/@{filenamePrefix}-regular.ttf") format("truetype"),
      url("@{fontpath}/@{filenamePrefix}-regular.svg#Roboto") format("svg");
  }
  /* roboto-700 - latin-ext_latin */
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("@{fontpath}/@{filenamePrefix}-700.eot");
    src: local(""), url("@{fontpath}/@{filenamePrefix}-700.eot?#iefix") format("embedded-opentype"),
      url("@{fontpath}/@{filenamePrefix}-700.woff2") format("woff2"),
      url("@{fontpath}/@{filenamePrefix}-700.woff") format("woff"),
      url("@{fontpath}/@{filenamePrefix}-700.ttf") format("truetype"),
      url("@{fontpath}/@{filenamePrefix}-700.svg#Roboto") format("svg");
  }
}

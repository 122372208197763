.dispute-claim {
  border-top: solid 1px @border-color1;
  background-color: @panel-color4;
  color: @font-color4;

  &__link {
    color: @font-color2;
  }

  .dispute-options {
    color: @font-color1;
  }
}

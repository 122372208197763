.payone {
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    fieldset {
      .row {
        display: flex;
        margin-bottom: 1.5rem;

        .item {
          width: 100%;
          .inputIframe span:not(:last-child)  {
            margin-right: 5px;
          }

          label {
            font-size: 13px;
            color: #7a7a7a;
          }
        }

        .item:not(:first-child) {
          margin-left: 2rem;
        }
      }

      .cardNumber {
        position: relative;

        img.hidden {
          display: none;
        }

        img#cardtype {
          position: absolute;
          right: 5px;
          top: 10px;
        }
      }

      #expireInput {
        display: flex;
      }

      .button-container {
        button {
          width: 100% !important;
        }
      }
    }

    #errorOutput:empty {
      display: none;
    }

    #errorOutput {
      background-color: #f5c6cb;
      color: #721c24;
      width: 100%;
      border-radius: 5px;
      font-size: small;
      text-align: center;
      padding: 20px;
      margin-bottom: 20px;
    }
  }
}

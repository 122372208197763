.customer {
  &__section {
    border: 1px solid black;
    padding: @main-side-padding;
  }

  .payment-details {
    &__title {
      padding-bottom: 8px;
    }

    &__item {
      font-size: 0.875rem;
      padding-bottom: 4px;
    }

    &__separator {
      background-color: black;
      margin: 4px 0 4px 0;
      height: 1px;
    }
  }
}

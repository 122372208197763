.title {
  .text-small {
    font-size: 0.875rem;
    line-height: 1.43;
    font-weight: 400;
  }

  h1,
  .text-h1 {
    font-size: 2.125em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.24;
  }
}

.modal {
  &__content {
    max-width: 640px;
    margin: 84px auto;
  }
}

.main > .body {
  .modal {
    &__footer {
      text-align: right;
    }

    &__body,
    &__header {
      padding: 16px 32px;
    }

    .column-right,
    .column-left {
      float: none;
      width: auto;
    }

    .column-left {
      margin-right: 16px;
    }
  }
}

.title__section {
  padding-left: 0;
}

.main--failure,
.main--error {
  .title {
    padding: 24px 0 0 0;
  }
}

@import "./variables/small.less";
@import "./mixins.less";

.font-face-roboto();

@import "./components/small/index.less";

.pass-code-page .form .title {
  padding: 16px 16px 0 0;
}

.mobile-only {
  display: block;
  padding: 1rem;
}

.desktop-only {
  display: none;
}

@media (min-width: @breakpoint-medium) {
  @import "./variables/medium.less";
  @import "./components/medium/index.less";
  .pass-code-page {
    width: 621px;
    padding: 28px 32px 24px 32px;
  }

  .pass-code-page .form button {
    width: 135px;
  }

  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}

@media (min-width: @breakpoint-large) {
  @import "./variables/large.less";
  @import "./components/large/index.less";
}

.sepa-ticket {
  border-top: solid 1px @border-color1;
  background-color: @panel-color2;

  &__label {
    color: @font-color2;
    width: 7rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  &__item {
    display: flex;
  }
  &__value {
    color: @font-color5;
    display: inline-flex;
    gap: 0.25rem;
    margin-left: 0.25rem;
    align-items: flex-start;
    flex-grow: 1;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    button {
      background: transparent;
      font-size: 1rem;
      cursor: pointer;
    }
  }
}

.payment-layout .payment-provider {
  padding: 16px @main-side-padding 0 0;

  .payment-frame {
    &__body {
      overflow: visible;
      padding: 24px @main-side-padding;
    }

    &__footer {
      padding: 12px @main-side-padding 16px @main-side-padding;
    }

    .btn-primary {
      display: inline-block;
      width: auto;
    }
  }

  #XS2A-Form {
    button {
      display: inline-block;
      padding: 0 32px;
      width: auto;
    }
  }
}

@import "./paymentClient/index.less";

.payment-modalities {
  padding: 5px @actions-side-space;

  &.requested {
    border-top: solid 1px @success-border-color;
    background-color: @success-background-color;
  }

  &__section {
    text-align: center;
    padding-bottom: 10px;
  }

  &__section > .installment-plan {
    text-align: left;
  }

  &.requested &__section {
    padding: 10px 0;
  }

  &__link {
    font-weight: bold;
    font-size: 0.875em;
  }

  &__items {
    margin-top: 16px;
  }

  &__item {
    padding: 5px 0;
  }

  .deferment-button-container {
    margin-bottom: 10px;

    .deferment-button {
      display: block;
      font-weight: 600;
      text-decoration: none;
      border: 2px solid #787878;
      border-radius: 4px;
    }
  }

  &__message.installment-plan{
 
    a {
      color: #393939;
      font-size: 14px;
    }

    i.arrow {
      vertical-align: unset;
      font-size: 16px;
    }
  }
}

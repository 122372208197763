.main--success {
  .buttons-section {
    grid-column-start: 1;
    grid-column-end: 13;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      margin: 5px;
      width: auto;
    }
  }

  .attachments {
    text-align: center;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    form {
      margin-right: 5px;
      margin-bottom: 10px;
    }

    .attachments__header {
      display: none;
    }
  }
}

.payment-provider {
  #XS2A-Form {
    .xs2a-form-line {
      &.xs2a-logo.xs2a-image {
        margin-top: -70px;
        width: max-content;
        text-align: left;
      }
    }
  }
}

.installment-plan-page {
  text-align: center;

  &__heading {
    margin-top: 32px;

    h2 {
      margin-top: 11px;
      font-size: 45px;
      font-weight: bold;
      color: #39393a;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      color: #1c2442;
    }
  }

  &__description {
    h4 {
      margin-bottom: 11px;
      font-size: 20px;
      font-weight: bold;
      color: #4d4d4d;
    }

    p {
      font-size: 14px;
      color: #666;
    }
  }

  &__back {
    margin-top: 52px;

    h3 {
      margin-bottom: 19px;
      font-size: 20px;
      font-weight: bold;
      color: #4d4d4d;
    }

    button {
      color: #787878;
      border: solid 2px #787878;
      background-color: rgba(255, 255, 255, 0.4);
    }
    button:hover {
      color: #fff;
      background-color: #787878;
    }
  }

  .sepa-mandate-container {
    margin-left: 5%;
    width: 90%;

    @media (min-width: 768px) {
      margin-left: 23.5%;
      width: 53%;
    }
  }
}
.expired-link {
  gap: var(--gap-24, 1.5rem);

  .expired-link-logo svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .expired-link-text {
    &__title {
      color: var(--text-primary, #15112C);
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
    }

    &__description {
      color: var(--text-secondary, #5F5F6D);
      text-align: center;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
}
.main {
  max-width: 100%;
  width: 100%;

  & > .header {
    width: 100%;

    .header__section {
      padding: 10px @main-side-padding;
    }
  }

  & > .body {
    width: 100%;
    padding-bottom: 40px;
  }

  & > .contact {
    width: 100%;

    &__section {
      padding: 18px @main-side-padding 42px @main-side-padding;
    }
  }

  & > .footer {
    width: 100%;

    &__section {
      padding: 28px @main-side-padding 40px @main-side-padding;
    }
  }
}

.payment-provider {
  &__section {
    border: 1px solid black;
  }

  .payment-frame {
    &__body {
      overflow: visible;
      padding: @main-side-padding;
    }

    &__footer {
      padding: 12px @main-side-padding;
      font-size: 0.75em;
      line-height: 1.5;
      text-align: left;
      border-top: solid 1px black;
      clear: both;
    }
    input[type="tel"],
    input[type="text"],
    select {
      border-radius: 4px;
      appearance: auto;
      -moz-appearance: auto;
      -webkit-appearance: auto;
    }
  }
}

@import "./paymentClient/index.less";

.main > .footer {
  padding: 0;

  .footer__section {
    width: 70%;
    max-width: @layout-max-width;
    min-width: @layout-min-width;
    margin: 0 auto;
    padding: 26px 0 40px;
  }
}

.status {
  &__icon {
    .main--success & {
      text-align: center;
      display: block;

      img {
        height: 100px;
      }
    }
  }
}

.message {
  padding: 16px @main-side-padding 16px @main-side-padding;

  p,
  .text,
  .text-emphasized {
    margin: 0 0 10px 0;

    .main--success & {
      font-size: 1.125rem;
    }
  }

  .dispute-details {
    dt,
    dd {
      font-style: italic;
    }

    dd {
      margin-left: 10px;
    }
  }
}

.dispute-claim {
  padding: 5px @actions-side-space;
  border-top: 1px solid black;

  &__message {
    font-size: 0.75em;
    text-align: left;
    line-height: 1.5;
  }

  &__section {
    padding: 5px 0 10px;
  }

  &__link {
    display: inline-block;
    margin-left: 4px;
  }

  .dispute-options {
    .dispute-option {
      overflow: hidden;
      position: relative;
      margin: 8px 0;
      max-height: 185px;

      &__label {
        width: calc(100% - 30px);
        min-height: 22px;
        display: inline-block;
        line-height: 1.5;
        font-size: 1em;
        margin-left: 32px;
        cursor: pointer;
      }

      &__radio {
        position: absolute;
      }

      &--closed {
        display: none;
      }
    }
  }
}

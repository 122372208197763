.payment-provider {
  position: relative;

  .btn-primary {
    margin: 8px 0;
    width: 100%;
  }

  .payment-frame {
    position: relative;
  }

  h2,
  h3 {
    margin-bottom: 12px;
  }
}

.frail-iframe {
  height: 643px;
  width: 100%;

  &__section {
    width: 100%;
    height: 643px;
    border: none;
  }
}

.your-pay {
  .frail-iframe {
    height: 500px;
  }
}

.barzahlen {
  .payment-frame {
    h3 {
      font-weight: bold;
      margin-bottom: 4px;
    }
  }
}

.trustly {
  // Frail Iframe overrides (all screens)
  .payment-frame {
    &__body {
      padding: 0;
    }
  }

  .frail-iframe,
  .frail-iframe__section {
    height: 405px;
  }
}

.computop {
  // Frail Iframe overrides
  .frail-iframe,
  .frail-iframe__section {
    height: 650px;
  }
}

.error-message {
  padding: 0 !important;
  margin: 8px 0 0 0;
}

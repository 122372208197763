.item-switch {
  margin: 5px 0;

  .button {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 50px;
    height: 20px;
    background-color: @switch-secondary-color;
    border: 0 solid @switch-primary-color;
    border-radius: 50px;
    margin: 0 6px;
    vertical-align: bottom;
    cursor: pointer;

    .icon {
      content: "";
      color: #fff;
      line-height: 18px;
      text-align: center;
      position: absolute;
      top: 1px;
      left: 1px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #666 url(common/icon_return.svg) center 3px no-repeat;
    }
  }

  &.enabled {
    .button {
      border-width: 1px;
      background-color: @switch-primary-color;
      border-color: @switch-primary-color;
    }

    .icon {
      top: 0;
      color: @switch-primary-color;
      left: 30px;
      background: #fff url(common/icon_checkmark.svg) center no-repeat;
    }
  }
}

.customer-feedback {
  width: 80%;
  padding: 2rem;
  background-color: transparent;
  .net-promoter-score {
    .scale {
      flex-direction: row;
    }
  }

  .customer-effort-score {
    .scale {
      display: flex;
      justify-content: space-between;
      margin: 1.75rem 0 0 0;
      flex-direction: row;

      div {
        flex-direction: row;
      }

      div:nth-of-type(1) input[type="radio"] {
        border: 0.15rem solid #e95d4a;
        margin-left: 8px;
        margin-right: 0;
      }

      div:nth-of-type(1) label,
      div:nth-of-type(2) label,
      div:nth-of-type(3) label,
      div:nth-of-type(4) label,
      div:nth-of-type(5) label,
      div:nth-of-type(6) label,
      div:nth-of-type(7) label {
        font-size: 0.9rem;
      }

      div:nth-of-type(2) label,
      div:nth-of-type(3) label,
      div:nth-of-type(4) label,
      div:nth-of-type(5) label,
      div:nth-of-type(6) label {
        display: none;
      }

      div:nth-of-type(2) input[type="radio"],
      div:nth-of-type(3) input[type="radio"],
      div:nth-of-type(4) input[type="radio"],
      div:nth-of-type(5) input[type="radio"],
      div:nth-of-type(6) input[type="radio"] {
        margin-right: 0;
      }

      div:nth-of-type(7) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .customer-satisfaction-score {
    .scale {
      button {
        background: transparent;
        cursor: pointer;
      }
    }
  }

  .send-feedback {
    textarea {
      height: 148px;
    }

    button {
      grid-column-start: 9;
      grid-column-end: 13;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

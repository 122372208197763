.contact-collection-page-layout {
  .margin-top-bottom-1 {
    margin: 1rem auto;
  }
  .main__status-image {
    display: inline-block;
    width: 2rem;
    vertical-align: middle;
  }

  .contact-collection-page {
    font-weight: 400;
    text-align: left;
    padding: 1.5rem 0.5rem 0 0.5rem;

    font-size: 1.25rem;
    &__title {
      line-height: 24px;
      font-weight: 600;
    }

    &__description {
      line-height: 28px;
      padding: 20px 0;
      padding-bottom: 10px;

      p {
        display: inline-block;
        vertical-align: top;
        color: @contact-purple-color;
      }
    }
  }
  .contact-collection {
    &__consent {
      padding-top: 20px;
      display: flex;

      label {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 600;
        width: 100%;
      }
      input[type="email"],
      input[type="tel"],
      input[type="number"] {
        width: 100%;
        margin-top: 8px;
        &.error {
          border-color: @contact-error-color;
          box-shadow: 0px 0px 5px 0px @contact-error-shadow-color;
        }
      }
      p.error {
        color: @contact-error-color;
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      input[type="checkbox"] {
        border-radius: 6px;
        color: @pass-code-line-primary-color;
        margin-right: 10px;
        &:focus {
          &::after {
            border-color: @contact-purple-color;
          }
        }

        &::after {
          border-color: @pass-code-line-primary-color;
          border-radius: 6px;
        }
        &:checked::after {
          background: @contact-purple-color;
        }
      }

      .text-container {
        font-size: 0.875rem;
        line-height: 22px;
        font-weight: 400;
        padding-right: 2rem;
        button {
          background: transparent;
          color: @contact-purple-color;
          text-decoration: underline;
        }
        .hidden {
          display: none;
          height: auto;
          font-size: inherit;
        }
      }
      .react-tel-input {
        input[type="tel"] {
          padding: 18.5px 14px 18.5px 58px;
          &:focus {
            border-color: @contact-purple-color;
            .box-shadow(0px 0px 5px 0px @input-focus-color);
          }
        }

        .selected-flag {
          width: 4.375rem;
          .flag {
            left: 1rem;
          }
          .arrow {
            margin-top: 0;
            position: absolute;
          }
        }
      }
    }
    &__button {
      margin-top: 16px;
      button,
      a {
        display: inline-block;
        width: 100%;
      }
    }
    &__footnotes {
      margin-top: 1rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: 0px;
      text-align: left;
      color: @footnotes-color;
    }
  }

  .contact-collection-sent {
    &__retry {
      margin-top: 0.63rem;
      font-size: 0.875rem;

      button {
        background-color: transparent;
        color: @contact-purple-color;
        text-decoration-line: underline;
      }
    }

    &__info {
      margin: 1rem 0;
      padding-bottom: 1.25rem;
    }
  }

  .contact-collection-sms-sent {
    width: 100%;

    &__buttons {
      display: flex;
      gap: 1.875rem;
      button {
        font-size: 1rem;
      }
    }

    &__info {
      margin-top: 0.5rem;
    }

    &__token-container {
      margin-top: 1.75rem;
      margin-bottom: 3.06rem;
      width: 100%;
      display: block;

      /* TODO: use mixins for this */
      /* Chrome, Safari, Edge, Opera - disable arrows on input */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox - disable arrows on input */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      p {
        font-size: 0.875rem;
        color: @contact-error-color;
        line-height: 1.375rem;
        margin-top: 0.75rem;
      }
    }

    &__token-input,
    &__token-input-error {
      padding: 0;
      width: 2.9rem !important;
      height: 2.9rem !important;
      margin: 0 0.3rem !important;
      font-size: 1.5rem;
      border-radius: 0.25rem;
      border: 1px solid !important;
      border-color: @contact-purple-color;
      color: @contact-purple-color;
      background-color: @contact-light-purple-color !important;

      &::placeholder {
        color: @contact-purple-color;
      }

      &[value=""] {
        background-color: transparent !important;
      }
    }

    &__token-input-error {
      border-color: @contact-error-color !important;

      &__detail {
        height: 1.375rem;
      }
    }
  }

  .contact-collection-invalid-page {
    min-height: 539px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
}

.message {
  padding-left: 0;
}

.main--failure,
.main--error {
  .message {
    padding-left: 0;
  }
}

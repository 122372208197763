@import (inline) "react-toastify/dist/ReactToastify.css";

:root {
  --toastify-color-info: #edf5fc !important;
  --toastify-color-success: #effbf6 !important;
  --toastify-color-warning: #fef7ec !important;
  --toastify-color-error: #fdedee !important;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  //Used only for colored theme
  --toastify-text-color-info: #1e1c31 !important;
  --toastify-text-color-success: #1e1c31 !important;
  --toastify-text-color-warning: #1e1c31 !important;
  --toastify-text-color-error: #1e1c31 !important;

  --toastify-color-strong-info: #61a8e5;
  --toastify-color-strong-success: #3dd196;
  --toastify-color-strong-warning: #f4bb52;
  --toastify-color-strong-error: #ed5a64;

  .Toastify__progress-bar--info {
    background: var(--toastify-color-strong-info) !important;
  }
  .Toastify__progress-bar--success {
    background: var(--toastify-color-strong-success) !important;
  }
  .Toastify__progress-bar--warning {
    background: var(--toastify-color-strong-warning) !important;
  }
  .Toastify__progress-bar--error {
    background: var(--toastify-color-strong-error) !important;
  }

  .Toastify__toast--info {
    svg {
      color: var(--toastify-color-strong-info) !important;
    }
  }
  .Toastify__toast--success {
    svg {
      color: var(--toastify-color-strong-success) !important;
    }
  }
  .Toastify__toast--warning {
    svg {
      color: var(--toastify-color-strong-warning) !important;
    }
  }
  .Toastify__toast--error {
    svg {
      color: var(--toastify-color-strong-error) !important;
    }
  }

  .Toastify__toast-body {
    font-size: 1rem;
    align-items: flex-start;
  }

  .Toastify__toast-close-button {
    margin: auto 0;
    padding-top: 0.3rem;
  }

  .snackbar-custom-message {
    .Toastify__toast-close-button {
      margin: 0.375rem 0 0 0;
      padding-top: 0;
    }
  }

  .Toastify__toast__advanced--text {
    color: #676779 !important;
    font-size: 0.875rem !important;
  }
}

.info-label {
    display: flex;

    &__info {
        color: @info-label-info-color;
    }

    &__warning {
        color: @info-label-warning-color;
    }

    &__message {
        font-size: 12px;
        margin-top: 4px;
        margin-left: 3px;
    }
}
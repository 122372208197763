.default-layout-error {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.default-error-page .error-icon {
  width: 20%;
}

.default-layout-error .default-error-page .title__section,
.default-layout-error .default-error-page .message__section {
  width: auto;
}

.default-layout-error .body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

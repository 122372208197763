.sepa-ticket {
  border-top: 1px black solid;
  padding: 5px @actions-side-space;

  &__section {
    padding: 10px 0;
  }

  &__description * {
    font-size: 0.875rem;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
  }

  &__items {
    padding: 0;
    list-style-type: none;
  }

  &__label {
    font-size: 0.75rem;
    font-weight: 500;
  }

  &__value {
    font-size: 0.875rem;
    width: min-content;
  }
}

.loader {
  width: 100%;

  .line-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 0;

    & > div {
      display: inline-block;
    }
    .circle {
      height: 20px;
      width: 20px;
      border-radius: 10px;
      margin: 4px 10px 4px 0;
      background-color: #f5f5f5;
    }
    .line-text {
      margin-right: 10px;
    }
  }

  .line-text {
    height: 20px;
    margin: 4px 0;
    border-radius: 2px;
  }

  .line-header {
    height: 30px;
    margin-bottom: 15px;
  }

  .line-icon {
    height: 30px;
    margin: 0;
  }
  .line-button {
    height: 38px;
    border-radius: 2px;
    margin-top: 15px;
  }

  .line-text,
  .line-icon,
  .line-button {
    background: linear-gradient(90deg, #f5f5f5, #d8d8d8, #f5f5f5, #d8d8d8);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .width20 {
    width: 20%;
  }
  .width40 {
    width: 40%;
  }
  .width50 {
    width: 50%;
  }
  .width60 {
    width: 60%;
  }
  .width70 {
    width: 70%;
  }
  .width80 {
    width: 80%;
  }
  .width90 {
    width: 90%;
  }
}

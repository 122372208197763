.contact-collection-initiator {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding: @main-side-padding;
  background-color: #FFFFFF;

  &__header {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 1.09rem;
    margin-bottom: 0.9rem;
  }

  &__description {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 0.9rem;
  }
}

@import "fintecSystems";
@import "payone";

.field {
  &--small,
  &--middle,
  &--large {
    display: inline-block;

    &:first-child {
      margin-right: 16px;
    }
  }

  &--small {
    width: calc(35% - 8px);
  }

  &--middle {
    width: calc(50% - 8px);
  }

  &--large {
    width: calc(65% - 8px);
  }

  &__btn-container {
    text-align: right;
  }
}

.better-payment {
  .form {
    .field-link {
      width: 100%;
      text-align: right;
    }
  }
}

.trustly {
  .payment-layout {
    .payment-provider .payment-frame__body {
      padding: 0;
    }
  }
}

.redirect {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  a {
    width: 90vw;
    margin: 0.5rem 0;
    display: inline-block;
  }
}

.waiting-text {
  font-size: 1rem;
  font-style: italic;
  text-align: center;
}

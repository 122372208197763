.contact {
  border-top: 1px solid @shadow-color1;
  background-color: @bg-color3;
  font-size: 0.875rem;

  &__title {
    font-weight: 300;
  }

  &__sub-title {
    font-size: 0.875rem;
  }
}

.form {
  &__sub-title {
    margin-bottom: 12px;

    &.text-small {
      font-size: 0.875rem;
      line-height: 1.43;
      margin-bottom: 18px;
    }
  }

  &__fieldset {
    margin-bottom: 20px;
  }

  &__group {
    margin-bottom: 8px;
  }

  .field-link {
    font-size: 0.75rem;
  }

  .field {
    margin-bottom: 16px;
    vertical-align: top;

    input {
      width: 100%;
    }

    &__label {
      font-size: 0.8125rem;
      font-weight: bold;
      line-height: 1.23;
      padding: 8px 0;
    }

    &__creditcard {
      background: url(common/thumbnails-m-creditcards.png) no-repeat right;
      margin-bottom: 4px;
    }

    &__checkbox {
      position: absolute;
    }

    .field__checkbox + .field__label {
      display: inline-block;
      font-weight: normal;
      text-align: left;
      float: none;

      max-width: calc(100% - 32px);
      width: 100%;

      margin-left: 32px;
      padding: 0;

      p {
        margin-bottom: 8px;
        .word-wrap(break-word);
      }
    }
  }
}

.better-payment {
  .form__sub-title {
    margin-bottom: 12px;
    font-size: 0.875rem;

    &.text-small {
      font-size: 0.875rem;
    }
  }
}

  .document {
      text-align: center;
      margin-top: 28px;

      &__container {
          display: inline-block;

          form button {
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
            text-decoration: underline;
            height: 2rem;
            text-align: end;
            margin: 0 0 1rem 0;
            font-size: inherit;
            cursor: pointer;
          }

          form button span {
            padding: 0 0 0 2.3rem;
          }
      }
  }
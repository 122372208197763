.title {
  background: transparent;
  padding: 0;

  h1,
  .text-h1 {
    font-size: 2.813rem;
  }

  &__section {
    padding: 24px 0 0 @main-side-padding;
  }
}

.main--failure,
.main--error {
  .title {
    padding: 24px @main-side-padding 0 @main-side-padding;

    &__section {
      width: calc(95% - 16px);
      padding: 0;
    }
  }
}

.main--success {
  .title {
    &__section {
      padding: 0;

      h1,
      .text-h1 {
        font-size: 1.75rem;
      }
    }
  }
}

.contact {
  padding: 0;

  &__title {
    margin: 0 0 17px 0;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.3334;
  }

  &__sub-title {
    margin: 10px 0;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }

  &__item {
    margin: 0 0 11px 0;
    list-style: none;
    text-align: left;
    text-decoration: none;
  }
}

.footer {
  border-top: 1px solid black;
  padding: 16px @main-side-padding 40px @main-side-padding;

  &__links {
    margin-bottom: 16px;
    font-size: 0.875em;
  }

  &__items {
    clear: both;
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  &__item {
    display: inline;
    list-style: none;
    margin-right: 16px;

    &.first {
      margin-left: 16px;
    }

    &.last {
      margin-right: 0;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__logo {
    clear: left;

    .logo {
      display: block;
      margin: 0 auto;
      height: 20px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      text-align: center;
      line-height: 20px;
      font-weight: 300;
      font-size: 1.3em;
    }
  }
}

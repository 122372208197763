h1,
h2,
h3,
h4,
h5,
h6,
p {
  outline: 0;
}

a {
  cursor: pointer;
  span {
    text-decoration: underline;
  }
}

.arrow {
  display: inline-block;
  margin: 0 0px -4px 4px;
}

.basic-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.2em;
  overflow: hidden;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 15px;
  white-space: nowrap;
  font-style: normal;

  &.info {
    background-image: url("common/icon_info.svg");
  }

  &.back {
    font-family: Arial, sans-serif;
    width: 0.7em;
    vertical-align: middle;
    line-height: 14px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }

  &.back::before {
    content: "\2039";
  }

  &.copy {
    background-image: url("common/icon_copy.svg");
    background-size: 18px;
    width: 1.25em;
    height: 1.25em;
  }

  &.arrow {
    background-image: url("common/icon_arrow_xs_3x.png");
  }
}

.xs2a-submit,
.btn-primary,
.btn-primary__inversed {
  height: 48px;
  border-radius: 8px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  line-height: 48px;
}

.btn-secondary {
  text-decoration: underline;
  background: transparent;
  outline: none;
  height: auto;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.btn-back {
  cursor: pointer;
  height: auto;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}

a.btn-back {
  display: inline-block;
  text-decoration: none;
}

.btn-container {
  padding: 16px 16px 0;
}

@input-radio: {
  width: 22px;
  height: 22px;
  cursor: pointer;
  vertical-align: middle;
};

@input-text: {
  background-color: white;
  font-size: 1rem;
  padding: 8px;
  border-radius: 0;
  border: none;
  display: block;
};

@input-checkbox: {
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
}

select,
input[type="email"],
input[type="text"],
input[type="tel"],
input[type="password"] {
  @input-text();
}

input[type="radio"] {
  @input-radio();
}

input[type="checkbox"] {
  @input-checkbox();
}

label {
  vertical-align: middle;
}

textarea {
  width: 100%;
  height: 148px;
  padding: 10px;
  margin: 8px auto 0 auto;
  overflow: auto;

  font-size: 1em;
  resize: none;

  .box-sizing(border-box);
}

#XS2A-Form {
  select,
  input[type="text"],
  input[type="password"] {
    @input-text();
  }

  input[type="radio"] {
    @input-radio();
  }

  input[type="checkbox"] {
    @input-checkbox();
  }
}

.field--select {
  input {
    background-color: white;
    font-size: 1rem;
    padding: 8px;

    border-radius: 0;
    border: none;
    display: block;
  }

  .auth-select {
    &__control {
      background-color: white;
      border-radius: 0;
      padding: 0;
      border: none;
    }

    &__indicators {
      display: inline-block;
    }

    &__dropdown-indicator {
      padding: 8px;
    }

    &__value-container {
      display: inline-block;
      padding: 0 0 0 8px;
      width: calc(~"100% - 28px");
    }

    &__option {
      cursor: pointer;
    }
  }
}

.item__text {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.field.field--large.flex-1 {
  margin-right: 0;
}

.text-small {
  font-size: 0.875rem;
  line-height: 1.43;
  margin-bottom: 18px;
}

summary.hide-details-marker::-webkit-details-marker {
  display: none;
}

summary.hide-details-marker {
  list-style: none;
}

.fine-print {
  color: @font-color1;
  font-size: 0.7rem;
}

.pdf-icon {
  background-image: url("common/pdf.svg");
  color: @font-color1;
}

.alert {
  .alert__section {
    width: calc(100% - 32px);
    max-width: @layout-max-width;
    min-width: @layout-min-width;
    padding: 10px 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }

  &__message {
    flex-grow: 2;
  }

  &__close {
    text-align: right;
    font-size: 1.5rem;
    cursor: pointer;
    min-width: auto;
    margin-top: -1px;
    margin-right: -8px;
    padding-right: 8px;
    padding-left: 8px;
    background-color: transparent;
    outline: none !important;
    font-weight: bolder;
  }

  &--error {
    background-color: @error-panel-color1;
    border-color: @error-panel-color1;

    color: @error-font-color2;

    .alert__close {
      color: @error-font-color2;
    }
  }

  &--success {
    border-color: @success-panel-color1;
    background-color: @success-panel-color1;

    color: @success-font-color1;

    .alert__close {
      color: @success-font-color1;
    }
  }

  &--warning {
    border-color: @warning-panel-color1;
    background-color: @warning-panel-color1;

    color: @warning-font-color1;

    .alert__close {
      color: @warning-font-color1;
    }
  }
}

.actions {
  margin: 24px 0 0 0;
  padding: 0;

  .claim-details,
  .payment-selector,
  .payment-modalities,
  .sepa-ticket,
  .dispute-claim {
    padding: 5px @actions-side-space;
  }
}

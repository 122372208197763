.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;

  top: 0;
  left: 0;
  display: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  line-height: 1.5;

  background: none;
  background-color: rgba(54, 56, 66, 0.7);

  min-width: @layout-min-width - 16px;

  &__section {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

  &__center {
    margin: 16px auto;
    padding: 0 16px;
  }

  &__content {
    border-radius: 4px;
    background-color: white;
  }

  &__header {
    background-color: rgb(218, 218, 218);
    padding: 15px 17px;
    border: none;

    h4 {
      font-size: 1.25em;
      font-weight: 300;
      text-align: left;
    }
  }

  &__body {
    padding: 15px 17px;
    text-align: left;
    margin-bottom: 0;
  }

  &__footer {
    background-color: rgb(218, 218, 218);
    overflow: hidden;
    padding: 16px;

    .column-left,
    .column-right {
      display: inline-block;
      text-align: center;
    }
    .column-left {
      float: left;
    }
    .column-right {
      float: right;
    }

    .btn-primary,
    .btn-secondary {
      width: auto;
    }
  }
}

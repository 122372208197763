.auth-form {
  padding: 28px 32px 24px 32px;

  .form {
    margin-bottom: 0;
  }

  .date-input {
    display: inline-block;
    width: calc(25% - 14px);
    margin-left: 14px;
    margin-bottom: 0;

    &:first-of-type {
      margin-left: 0;
    }

    .field {
      &.first {
        margin-left: 0;
      }
    }
  }

  .btn-primary {
    display: inline-block;
    width: 25%;
    margin-left: 14px;
    margin-bottom: 0px;
    vertical-align: top; /* fix for IE11 */
  }

  .field {
    &--text-auth {
      display: inline-block;
      width: calc(75% - 14px);

      margin-bottom: 0;
    }
  }
}

#XS2A-Form {
  .xs2a-completion-results {
    border: 1px solid @border-color1;
    background-color: @color5;
  }

  .xs2a-form-line {
    &.xs2a-logo.xs2a-image {
      text-align: center;
      .xs2a-logo-text {
        font-size: 0.6em;
        display: block;
        padding-top: 10px;
      }
      .xs2a-logo-image {
        height: 35px;
        width: 99px;
      }

      .xs2a-note {
        font-size: 0.813rem;
        display: block;
        padding-bottom: 10px;
        svg {
          height: 18px;
          width: 32px;
        }
      }
    }
  }

  .xs2a-completion-result-active {
    background: @color6;
  }

  input[type="checkbox"] {
    position: absolute;
  }

  input[type="checkbox"] + label {
    cursor: default;
    width: calc(100% - 24px - 8px);
    margin: 6px 0 0 32px;
    padding: 0;

    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.6;

    word-wrap: break-word;
    color: @font-color4;
  }

  .xs2a-submit {
    background-color: @color1;
  }
}

.zadl-modal.modal {
  color: @font-color1;

  .modal__body {
    padding-top: 32px;
    overflow: auto;

    h2 {
      font-size: 1.625rem;
      font-weight: 300;
      margin-bottom: 16px;
    }

    p,
    address,
    ul,
    li {
      font-size: 0.875rem;
      color: @font-color1;
    }

    p,
    address,
    ul {
      white-space: pre-line;
      margin: 0 0 0.875rem;
      color: @font-color1;
    }

    ul {
      padding: 0 0 0 2rem;
    }
  }

  .modal__footer {
    text-align: right;
  }
}

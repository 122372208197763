.claim-items {
  border-top: 1px @border-color1 solid;
  border-bottom: 1px @border-color1 solid;
  background-color: @panel-color3;

  .claim-item {
    .box-shadow(inset 0 -1px 0 0 @border-color1);
    &__date {
      font-weight: 300;
    }
  }

  &__footer,
  &__header {
    .claim-item {
      border: none;
      .box-shadow(none);
    }
  }

  &__header {
    .box-shadow(inset 0 -1px 0 0 @border-color1);
    color: @font-color4;
    font-weight: 500;
  }
}

.main > .body {
  position: relative;

  .body__section {
    width: 70%;
    max-width: @layout-max-width;
    min-width: @layout-min-width;
    margin: 0 auto;
    padding: 0;
  }
}
